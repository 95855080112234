<template>
  <v-container>
    This is the Course Queue
    - When a person purchases a course - If the course requires scheduling it will populate here.
    - Have the ability to add a student to a course queue here - if they purchase in store and want to manage it here.
    - Have the ability to remove a student from the queue
    - Have the ability to clear/empty the queue
    - If the student wanted a private - they will be marked as requested private - which will show a notification somehow
  </v-container>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style scoped>

</style>
